#recipe {
  #recipe-carousel {
    margin-bottom: 32px;
  }

  .recipe-infos {
    h4 {
      margin: 0 0 2px 0;
      color: #777;
      font-weight: bold;
    }
    p {
      margin-bottom: 20px;
    }
  }
  .recipe-instructions {
    li::marker {
      width: 30px;
      text-align: right;
      margin-right: 10px;
      display: inline-block;
    }

    ol {
      list-style-type: none;
      list-style-type: decimal !ie; /*IE 7- hack*/

      margin: 0;
      margin-left: 2em;
      padding: 0;

      counter-reset: li-counter;
      li {
        position: relative;
        margin-bottom: 15px;
        padding: 1em;
        counter-increment: step-counter;
        background-color: #fff;
        border-left: 0.1em solid @cc-green;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
      }
      li:before {
        position: absolute;
        top: 0;
        left: -1.2em;
        width: 1.2em;
        height: 1em;

        font-size: 1.9em;
        line-height: 0.94;
        text-align: center;
        color: #f8f8f8;
        cursor: default;

        background-color: @cc-green;
        content: counter(li-counter);
        counter-increment: li-counter;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
      }
    }
  }
}
