.dropdown-large {
  .dropdown-menu-large {
    min-width: 650px;
    padding: 20px 0;

    & > li > ul {
      padding: 0;
      margin: 0;
      & > li {
        list-style: none;
        & > a {
          display: block;
          padding: 3px 20px;
          clear: both;
          font-weight: normal;
          line-height: 1.428571429;
          color: #333333;
          white-space: normal;
          &:hover,
          &:focus {
            text-decoration: none;
            color: #262626;
            background-color: #f5f5f5;
          }
        }
      }
    }
    .dropdown-header {
      color: @cc-green;
      font-size: 18px;
    }
  }
}


@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0;
    margin-right: 0;
    .divider {
      display: none;
    }

    & > li:last-child {
      margin-bottom: 0;
    }

    .dropdown-header {
      padding: 3px 15px !important;
    }
  }
}
