.module-wrapper {
  margin-bottom: 30px;
  max-width: 375px;
  .module-content {
    padding: 10px;
    background-color: #fff;
    min-height: 150px;
    font-weight: bold;
  }
}

.module {
  &.listing {
    li {
      padding: 15px 0;
      border-bottom: 1px solid @color-border-bottom;

      .image-container {
        position: relative;
        float: left;
      }

      .data-container {
        margin-left: 125px;
        overflow: hidden;
      }
    }
    li:last-child {
      border: none;
    }
  }
}
