body {
  #wrap {
    min-height:100%;
    position:relative;
    padding-bottom: 220px;
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 50px;
    width: 100%;
    background: none repeat scroll 0 0 #252525;
    border-top: 0 none;
    padding: 15px 0;
    color: @color-footer;
  }
}
