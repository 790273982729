body {
  margin:0;
  padding:0;
  height: 86vh;
  background-color: #f8f8f8;

  img.top-border {
    border-top: 2px solid @cc-green;
  }

  #cookie-notice {
    color: #999;
    background-color: #252525;
    display: block;
    padding: 3px 0;
    a, button {
      color: #fff;
    }
  }

  .cc-header {
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 5px;
  }

  .search-results-info {
    margin-bottom: 30px;
  }

  ul.cloud {
    line-height: 2.5em;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  ul.cloud li {
    display: inline;
    margin-left: 4px;
  }
}

.section {
  margin-bottom: 30px;
}

.page-header {
  margin-top: 0;
}

.recipe-title {
  font-size: 150%;
  font-weight: bolder;
  margin-bottom: 0;
}

.shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}

hr.soften {
  background-image: -moz-linear-gradient(left center, transparent, rgba(0, 0, 0, 0.1), transparent);
  border: 0 none;
  height: 1px;
}

.hidden {
  display: none;
}

.input.inline {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}
